import { render, staticRenderFns } from "./sysDictionaryDetail.vue?vue&type=template&id=dbb20af6&"
import script from "./sysDictionaryDetail.vue?vue&type=script&lang=js&"
export * from "./sysDictionaryDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dbb20af6')) {
      api.createRecord('dbb20af6', component.options)
    } else {
      api.reload('dbb20af6', component.options)
    }
    module.hot.accept("./sysDictionaryDetail.vue?vue&type=template&id=dbb20af6&", function () {
      api.rerender('dbb20af6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/superAdmin/dictionary/sysDictionaryDetail.vue"
export default component.exports